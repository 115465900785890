<template>
  <el-aside width="230px">
    <div class="front-container">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu :router="true">
          <el-menu-item
            :index="route.name"
            v-for="route in sidebarRouters[0].children"
            :key="route.name"
            :route="route.url"
            :disabled="route.disabled"
          >
            <i :class="route.meta.icon"></i>
            <span slot="title" v-text="route.meta.title"></span>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
    </div>
  </el-aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["sidebarRouters"]),
  },
  methods: {
    resizeHandler() {
      //TODO .front-container el-scrollbar 更新一下 height
    },
  },
  created() {
    //00
  },
  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>

<style>
.el-menu {
  padding-top: 22px;
}
.el-menu li:first-child,
li:nth-child(4) {
  border-bottom: 1px solid #ebeef5;
}
</style>
